/*Theme Dark Deep Purple Styles*/
$app-primary-dark-deep-purple: #734fc3 !default;
$secondary-dark-deep-purple: #FFAB00;

$sidebar-dark-deep-purple: #252525 !default;
$sidebar-text-dark-deep-purple: #a1a1a1 !default;
$sidebar-darken-deep-purple: darken($sidebar-dark-deep-purple, 3%);
$sidebar-bg-darken-highlight-deep-purple: $app-primary-dark-deep-purple;

// Links
$link-hover-dark-deep-purple: $white;

//-Base-scss
.dark-deep-purple .right-arrow {
  color: $app-primary-dark-deep-purple;
  &:after {
    color: $app-primary-dark-deep-purple;
  }
}

//- Bootstrap file Style
.dark-deep-purple {
  a,
  .card-link,
  .jr-link,
  .jr-link.text-primary {
    color: $app-primary-dark-deep-purple;
    &:focus,
    &:hover {
      color: darken($app-primary-dark-deep-purple, 10%);
    }
  }

  & .text-primary {
    color: $app-primary-dark-deep-purple !important;
  }

  & .page-link {
    color: $app-primary-dark-deep-purple;
    &:focus,
    &:hover {
      color: $app-primary-dark-deep-purple;
    }
  }

  & .page-heading .breadcrumb-item.active {
    color: $app-primary-dark-deep-purple;
  }

  & .bg-primary,
  & .badge-primary {
    background-color: $app-primary-dark-deep-purple !important;
    color: $link-hover-dark-deep-purple !important;
  }

  & .btn-primary {
    background-color: $app-primary-dark-deep-purple;
    border-color: $app-primary-dark-deep-purple;
    color: $link-hover-dark-deep-purple;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($app-primary-dark-deep-purple, 5%) !important;
      border-color: darken($app-primary-dark-deep-purple, 5%) !important;
      color: $link-hover-dark-deep-purple !important;
    }
  }

  & .bg-primary {
    &.lighten-4 {
      background-color: #d1c4e9 !important;
    }

    &.lighten-3 {
      background-color: #b39ddb !important;
    }

    &.lighten-2 {
      background-color: #9575cd !important;
    }

    &.lighten-1 {
      background-color: #7e57c2 !important;
    }

    &.darken-1 {
      background-color: #5e35b1 !important;
    }

    &.darken-2 {
      background-color: #512da8 !important;
    }

    &.darken-3 {
      background-color: #4527a0 !important;
    }

    &.darken-4 {
      background-color: #311b92 !important;
    }

    &.accent-1 {
      background-color: #b388ff !important;
    }

    &.accent-2 {
      background-color: #7c4dff !important;
    }

    &.accent-3 {
      background-color: #651fff !important;
    }

    &.accent-4 {
      background-color: #6200ea !important;
    }
  }
}

//Secondary
.dark-deep-purple {
  .jr-link.text-secondary {
    color: $secondary-dark-deep-purple;
    &:focus,
    &:hover {
      color: darken($secondary-dark-deep-purple, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-dark-deep-purple !important;
  }

  & .bg-secondary,
  & .badge-secondary {
    background-color: $secondary-dark-deep-purple !important;
    color: $link-hover-dark-deep-purple !important;
  }

  & .btn-secondary {
    background-color: $secondary-dark-deep-purple;
    border-color: $secondary-dark-deep-purple;
    color: $link-hover-dark-deep-purple;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-dark-deep-purple, 5%) !important;
      border-color: darken($secondary-dark-deep-purple, 5%) !important;
      color: $link-hover-dark-deep-purple !important;
    }
  }
}

//_header.scss
.dark-deep-purple .app-main-header {
  background-color: $app-primary-dark-deep-purple !important;

  & .search-dropdown input {
    @media screen and (max-width: 991px) {
      background-color: $white;
    }
  }
}

//_right-sidebar.scss
.dark-deep-purple .color-theme-header {
  background-color: $app-primary-dark-deep-purple;

}

//_sidebar.scss
.dark-deep-purple .side-nav {
  background-color: $sidebar-dark-deep-purple !important;
  color: $sidebar-text-dark-deep-purple !important;
  @include box-shadow(none);

  & .user-profile {
    background-color: $sidebar-darken-deep-purple;
    @include box-shadow(none);
    position: relative;
    z-index: 2;
  }

  & .customizer {
    border-bottom: 0 none;
  }

  & .user-detail {
    & .user-name {
      color: $link-hover-dark-deep-purple;
    }
  }
}

.dark-deep-purple ul.nav-menu li button,
.dark-deep-purple ul.nav-menu li a {
  color: $sidebar-text-dark-deep-purple;
}

.dark-deep-purple ul.nav-menu {
  & .nav-header {
    color: $sidebar-text-dark-deep-purple;
  }
}

.dark-deep-purple ul.nav-menu > li.open > button,
.dark-deep-purple ul.nav-menu > li > button:hover,
.dark-deep-purple ul.nav-menu > li > button:focus,
.dark-deep-purple ul.nav-menu > li.open > a,
.dark-deep-purple ul.nav-menu > li > a:hover,
.dark-deep-purple ul.nav-menu > li > a:focus {
  background-color: $sidebar-darken-deep-purple;
  color: $link-hover-dark-deep-purple;
}

.dark-deep-purple ul.nav-menu li.menu.open > a {
  border-color: $secondary-dark-deep-purple;
}

.dark-deep-purple ul.nav-menu li.menu > button:focus:before,
.dark-deep-purple ul.nav-menu li.menu > button:hover:before,
.dark-deep-purple ul.nav-menu li.menu > a:focus:before,
.dark-deep-purple ul.nav-menu li.menu > a:hover:before {
  color: $link-hover-dark-deep-purple;
}

.dark-deep-purple ul.nav-menu li ul {
  background-color: $sidebar-darken-deep-purple;
}

.dark-deep-purple ul.nav-menu li.menu > button:before,
.dark-deep-purple ul.nav-menu li.menu > a:before {
  color: $sidebar-text-dark-deep-purple;
}

.dark-deep-purple ul.nav-menu li.menu.open > a:before,
.dark-deep-purple ul.nav-menu li.menu > a:focus:before,
.dark-deep-purple ul.nav-menu li.menu li > a:hover:before {
  color: $link-hover-dark-deep-purple;
}

.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:before {
  color: $sidebar-text-dark-deep-purple;
}

.dark-deep-purple ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: $sidebar-bg-darken-highlight-deep-purple;
  color: $link-hover-dark-deep-purple;
}

.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-deep-purple ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: $sidebar-darken-deep-purple;
  color: $link-hover-dark-deep-purple;
}

/*Header top Navbar Styles*/
.dark-deep-purple .navbar-nav {
  & li {
    &:hover > a,
    &:focus > a,
    &:hover > .nav-link,
    &:focus > .nav-link {
      color: $app-primary-dark-deep-purple;
    }

    & a,
    & .nav-link {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      color: $app-primary-dark-deep-purple;
    }
  }

  & li.nav-item > a,
  & li.nav-item > .nav-link {
    color: $white;
  }

  & li.nav-item:hover > a,
  & li.nav-item:focus > a,
  & li.nav-item > a:hover,
  & li.nav-item > a:focus,
  & li.nav-item.active > a,
  & li.nav-item:hover > .nav-link,
  & li.nav-item:focus > .nav-link,
  & li.nav-item > .nav-link:hover,
  & li.nav-item > .nav-link:focus,
  & li.nav-item.active > .nav-link {
    color: $secondary-dark-deep-purple;
  }

  ul.sub-menu {
    & li a.active,
    & li.active > a,
    & li .nav-link.active,
    & li.active > .nav-link {
      color: $app-primary-dark-deep-purple;
    }
  }

  & .nav-arrow {
    & > a:before,
    & > .nav-link:before {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      &:before {
        color: $app-primary-dark-deep-purple;
      }
    }

    &:hover > a:before,
    &:focus > a:before,
    &.active > a:before,
    &:hover > .nav-link:before,
    &:focus > .nav-link:before,
    &.active > .nav-link:before {
      color: $app-primary-dark-deep-purple;
    }
  }
}

.dark-deep-purple .app-top-nav {

  & .navbar-nav {
    & li.nav-item > a,
    & li.nav-item > .nav-link {
      color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a,
    & li.nav-item:hover > .nav-link,
    & li.nav-item:focus > .nav-link,
    & li.nav-item > .nav-link:hover,
    & li.nav-item > .nav-link:focus,
    & li.nav-item.active > .nav-link {
      color: $secondary-dark-deep-purple;
    }
  }
}

.dark-deep-purple .app-header-horizontal {
  & .app-main-header-top {
    @include box-shadow(none !important);
  }
}

.dark-deep-purple .header-notifications .app-notification {
  & .jr-list-link {

    @include hover-focus-active {
      color: $app-primary-dark-deep-purple;
      border-color: $app-primary-dark-deep-purple;
    }
  }
}

//_app-module.scss
.dark-deep-purple .module-nav {
  & li a {
    &.active {
      border-color: $app-primary-dark-deep-purple;
    }
  }
}

//_calendar.scss
.dark-deep-purple .rbc-event {
  background-color: $app-primary-dark-deep-purple;
}

.dark-deep-purple .rbc-event.rbc-selected {
  background-color: darken($app-primary-dark-deep-purple, 10%);
}

.dark-deep-purple .rbc-slot-selection {
  background-color: rgba($app-primary-dark-deep-purple, 0.7);
}

.dark-deep-purple .rbc-toolbar button:active,
.dark-deep-purple .rbc-toolbar button.rbc-active {
  background-color: rgba($app-primary-dark-deep-purple, 0.9);
  border-color: $app-primary-dark-deep-purple;
}

.dark-deep-purple .rbc-toolbar button:active:hover,
.dark-deep-purple .rbc-toolbar button.rbc-active:hover,
.dark-deep-purple .rbc-toolbar button:active:focus,
.dark-deep-purple .rbc-toolbar button.rbc-active:focus {
  background-color: rgba($app-primary-dark-deep-purple, 0.9);
  border-color: $app-primary-dark-deep-purple;
}

.dark-deep-purple .rbc-toolbar button:focus {
  background-color: rgba($app-primary-dark-deep-purple, 0.9);
  border-color: $app-primary-dark-deep-purple;
}

.dark-deep-purple .rbc-toolbar button:hover {
  background-color: rgba($app-primary-dark-deep-purple, 0.9);
  border-color: $app-primary-dark-deep-purple;
}

//_chat.scss
.dark-deep-purple .chat-sidenav-title {
  color: $app-primary-dark-deep-purple;
}

.dark-deep-purple .chat-user-item {
  &.active,
  &:hover {
    background-color: lighten($app-primary-dark-deep-purple, 45%);
  }
}

//_dashboard.scss
.dark-deep-purple .contact-list {
  & i {
    color: $app-primary-dark-deep-purple;
  }
}

//The link which when clicked opens the collapsable ChatUserList
.dark-deep-purple .Collapsible__trigger {
  background: $app-primary-dark-deep-purple;
}

.dark-deep-purple .categories-list {
  & li a:focus,
  & li a:hover,
  & li.active a {
    color: $app-primary-dark-deep-purple;
  }
}

//_login.scss
.dark-deep-purple .login-content .form-control {
  &:focus {
    border-color: $app-primary-dark-deep-purple;
  }
}

//_portfolio.scss
.dark-deep-purple .filter-with-bg-color ul li {
  .jr-link {
    border-color: $app-primary-dark-deep-purple;

    &:hover,
    &:focus,
    &.active {
      background-color: $app-primary-dark-deep-purple;
    }
  }
}

//_card.scss
.dark-deep-purple .profile-intro {
  & .icon {
    color: $app-primary-dark-deep-purple;
  }
}

.dark-deep-purple .social-link {
  & li.active a,
  & li a:hover,
  & li a:focus {
    color: $secondary-dark-deep-purple;
  }
}

//_tables.scss
.dark-deep-purple .actions {
  color: $secondary-dark-deep-purple;
}

.dark-deep-purple .table-hover tbody tr:hover {
  background-color: rgba($app-primary-dark-deep-purple, 0.075);
}

//Border Color

.dark-deep-purple .border-primary {
  border-color: $app-primary-dark-deep-purple !important;
}

// login page content

.dark-deep-purple .app-logo-content {
  background-color: $app-primary-dark-deep-purple;
}

.dark-deep-purple .app-social-block {
  & .social-link button {
    border: solid 1px $app-primary-dark-deep-purple;
    color: $app-primary-dark-deep-purple;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary-dark-deep-purple;
    }
  }
}

/*Button Group Styles*/
.dark-deep-purple .btn-group,
.dark-deep-purple .btn-group-vertical {
  > .jr-btn {
    &.active {
      background-color: $app-primary-dark-deep-purple;
      border-color: $app-primary-dark-deep-purple;
    }

  }

  > .jr-flat-btn {
    background-color: transparent;
    border-color: transparent;
    &.active {
      background-color: transparent;
      color: $app-primary-dark-deep-purple;
      &:hover,
      &:focus {
        background-color: rgba($app-primary-dark-deep-purple, 0.12);
        color: $app-primary-dark-deep-purple;
      }
    }
  }
}

/*Classic Dashboard Styles*/
.dark-deep-purple .jr-fillchart-btn-close,
.dark-deep-purple .jr-onchart .jr-badge-up,
.dark-deep-purple .jr-task-list-item:hover .jr-text-hover {
  color: $app-primary-dark-deep-purple;
}

.dark-deep-purple .jr-entry-title:before {
  background-color: $app-primary-dark-deep-purple;
}

.dark-deep-purple .jr-card-ticketlist {
  & .jr-task-list-item:hover .jr-task-item-title,
  .jr-link {
    color: $app-primary-dark-deep-purple;
  }
}

.dark-deep-purple .slick-dots li.slick-active button:before {
  border-color: $app-primary-dark-deep-purple;
}

//Nav Styles
.dark-deep-purple .nav-pills .nav-link.active,
.dark-deep-purple .nav-pills .show > .nav-link {
  color: $white !important;
  background-color: $app-primary-dark-deep-purple;
}

// Gradient Color Class
.dark-deep-purple .bg-gradient-primary {
  @include gradient-directional($app-primary-dark-deep-purple, lighten($app-primary-dark-deep-purple, 16%), 0deg);
}

.dark-deep-purple .bg-gradient-primary-x {
  @include gradient-x(darken($app-primary-dark-deep-purple, 10%), lighten($app-primary-dark-deep-purple, 16%), 70%, 100%);
}

//Profile style
.dark-deep-purple .jr-profile-banner {
  background-color: $app-primary-dark-deep-purple;
  color: $white;

  & .jr-link {
    color: $white;

    &:hover,
    &:focus {
      color: $secondary-dark-deep-purple;
    }
  }
}

.dark-deep-purple .dropdown-item {

  &.active,
  &:active {
    background-color: $app-primary-dark-deep-purple;
    color: $white;
  }

  &[class*="text-"] {

    &.active,
    &:active {
      background-color: transparent;
    }
  }
}
